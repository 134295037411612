import * as Styled from "./headerLogoWithParagraphStyles";
import React from "react";

const HeaderLogoWithParagraph = ({ sectionData }) => {
  return (
    <Styled.MainDiv>
      <Styled.Card>
        <Styled.Center>
          <Styled.Image
            src={sectionData?.image?.url}
            alt={sectionData?.image?.title}
          />
        </Styled.Center>
        <Styled.Paragraph>{sectionData?.text?.text}</Styled.Paragraph>
      </Styled.Card>
    </Styled.MainDiv>
  );
};

export default HeaderLogoWithParagraph;
