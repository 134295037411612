import * as React from "react"
import HomePageTemplate from '../templates/homePageTemplate/homePageTemplate'
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Fevicon from '../images/fevicon.png';

const IndexPage = () => {

  
  return (
    <>
        <Helmet>
        <link rel="icon" href={Fevicon} type="image/png" />
        </Helmet>
      <HomePageTemplate/>
    </>
  )
}

export default IndexPage;

export const pageQuery = graphql`
  fragment PageContent on ContentfulContent {
    id
    title
    type
    header
    subText
    elementId
    contentDetails {
      raw
    }
  }

  fragment ContentList on ContentfulContentList {
    id
    title
    type
    header
    subtext
    elementId
    list {
      title
      type
      header
      subText
      elementId
      contentDetails {
        raw
      }
    }
    listDetails {
      raw
    }
  }
  fragment TextWithImage on ContentfulTextWithImage {
    id
    title
    type
    image {
      url
      title
    }
    text {
      text
      id
    }
  }
  fragment ImagesWithPathList on ContentfulListOfImageWithPath {
    id
    title
    type
    listOfImagesWithPath {
      title
      type
      path
      richText {
        raw
      }
      image {
        file {
          fileName
          url
        }
      }
    }
  }

  fragment ListTextWithPath on ContentfulListTextWithPaths {
    id
    title
    type
    textWithPaths {
      id
      text
      path
    }
  }
`;
