import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import 'bootstrap/dist/css/bootstrap.css';
import NavigatioBar from '../../components/navigationBar/mainMenu/navigationBar';
import Footer from '../../components/footer/footer';
import * as Styled from './homePageTemplateStyles';
import GlobalStyles from '../../styles/GlobalStyles';
import HeaderLogoWithParagraph from '../../components/headerLogoWithParagraph/headerLogoWithParagraph';
import Corousel from '../../components/corousel/corousel';
import ContentlistWithImage from '../../components/contentlistWithImage/contentlistWithImage';
import ContentWithImageAndIcons from '../../components/contentWithImageAndIcons/contentWithImageAndIcons';
import Fevicon from '../../images/fevicon.png';
import '../../styles/style.css';

const HomePageTemplate=({location})=>{
    React.useEffect(() => {
        if (!location?.hash && typeof window !== 'undefined')
        {window.scrollTo(0, 0);}
        }, []);
    const homePageSectionsData = useStaticQuery(
        graphql`
        query allHomePageQuery {
            allContentfulSolEcolution(
            filter: {identifier: {type: {eq: "homePage"}}, node_locale: {eq: "en-US"}}
        ) {
            edges {
                node {
                    name
                    slug
                    title
                    node_locale
                    hasIndex
                    sections {
                    ... on ContentfulContentList {
                        id
                        title
                        type
                        list {
                        title
                        type
                        contentDetails {
                            raw
                        }
                        }
                        listDetails {
                            raw
                        }
                    }
                    ... on ContentfulTextWithImage {
                        id
                        title
                        type
                        image {
                        url
                        title
                        }
                        text {
                            text
                            id
                        }
                    }
                    ... on ContentfulListOfImageWithPath {
                        id
                        type
                        listOfImagesWithPath {
                        title
                        type
                        image {
                        file {
                            url
                        }
                        }
                        richText {
                        raw
                        }
                    }
                    }
                    }
                }
            }
        }
        }
        `);

    const title = homePageSectionsData?.allContentfulSolEcolution?.edges[0]?.node?.title || 'Home';
    const corousel = homePageSectionsData?.allContentfulSolEcolution?.edges[0]?.node?.sections[0];
    const headerLogoWithParagraph = homePageSectionsData?.allContentfulSolEcolution?.edges[0]?.node?.sections[1];
    const contentlistWithImage = homePageSectionsData?.allContentfulSolEcolution?.edges[0]?.node?.sections[2];
    const contentWithImageAndIcons = homePageSectionsData?.allContentfulSolEcolution?.edges[0]?.node?.sections[3];
    return <>
    <GlobalStyles/>
    <Helmet>
        <title>
            {title}
        </title>
        <link rel="icon" href={Fevicon} type="image/png" />
        {homePageSectionsData?.allContentfulSolEcolution?.edges[0]?.metadata && (
            <meta
            name="description"
            content={homePageSectionsData?.allContentfulSolEcolution?.edges[0]?.metadata}
            ></meta>
        )}
        {homePageSectionsData?.allContentfulSolEcolution?.edges[0]?.node?.hasIndex === false && <meta name="robots" content="noindex" />}
    </Helmet>
    <NavigatioBar activeLink="/" />
    <Styled.CommonPageContainer>
    
    <Corousel sectionData={corousel}/>
    <HeaderLogoWithParagraph sectionData={headerLogoWithParagraph} />
    <ContentlistWithImage sectionData={contentlistWithImage} />
    <ContentWithImageAndIcons sectionData={contentWithImageAndIcons} />
    </Styled.CommonPageContainer>
    <Footer/>
    </>
};

export default HomePageTemplate;

